
  import DashboardCard from '@/components/dashboards/Components/DashboardCard.vue'
  import PeriodRecords from '@/components/dashboards/Generics/PeriodRecords.vue'
  import { utcToLocal } from '@/utils/general'
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { filterDates } from '../types'

@Component({
  components: {
    DashboardCard,
    PeriodRecords,
  },
})
  export default class ConsignmentResults extends Vue {
  @Prop() chartData: Record<string, any>
  @Prop({ default: 'Resultado general de consignación', type: String }) title
  @Prop({ default: 1, type: Number }) multiplier
  @Prop({
    default: () => {
      return {
        scheduledInspections: true,
        purchases: true,
        approvedCredits: true,
        signedCredits: true,
        reserves: true,
        sales: true,
      }
    },
  }) displayChart

  @Prop({ default: undefined, type: Number }) idEmployee

  getLeadAppraisals (records) {
    const convertedRecords = this.addAcquisitionTypeBooleans(records)
    return convertedRecords
      .map(lead => {
        const appraisals = []
        lead.deals?.forEach(deal => {
          deal.appraisals?.forEach(appraisal => {
            appraisals.push(appraisal)
          })
        })
        return appraisals
      })
      .flat()
      .filter(appraisal => appraisal)
  }

  getLeadInspections (records) {
    const appraisals = this.getLeadAppraisals(records)
    return appraisals
      .map(appraisal => appraisal.inspection)
      .filter(inspection => inspection)
  }

  getLeadNegotiations (records) {
    const inspections = this.getLeadInspections(records)
    return inspections
      .map(inspection => inspection.negotiation)
      .filter(negotiation => negotiation)
  }

  addAcquisitionTypeBooleans (records) {
    const pipelines = ['purchase', 'renewal', 'financed_renovation']
    return records
      .filter(record => pipelines.includes(record.pipeline.name))
      .map(record => {
        record.deals = record.deals.map(deal => {
          deal.appraisals = deal.appraisals.map(appraisal => {
            const lastAppraisedResponse = appraisal?.lastAppraisedResponse
            const appraisalAgreedAmount = Number(appraisal?.agreedAmount)
            const purchaseAmount = Number(lastAppraisedResponse?.amount)

            const responses = appraisal.responses
            const isConsignment = appraisalAgreedAmount ? (appraisalAgreedAmount > purchaseAmount) : responses.some(response => response.responseType.name === 'expected_offer_consignment')

            const inspection = appraisal.inspection
            if (inspection) {
              inspection.isConsignment = isConsignment
              const negotiation = inspection?.negotiation
              if (negotiation) {
                const negotiationAmount = Number(negotiation?.agreedAmount)
                const negotiationLastOffer = Number(negotiation?.lastOffer?.amount)
                inspection.negotiation.isConsignment = negotiationAmount > negotiationLastOffer
                inspection.negotiation = negotiation
              }
              appraisal.inspection = inspection
            }
            return { ...appraisal, isConsignment }
          })
          return {
            ...deal,
            isConsignment: deal.appraisals.some(appraisal => appraisal.isConsignment),
          }
        })

        const isConsignment = record.deals.some(deal => deal.isConsignment)

        return {
          ...record,
          isConsignment,
        }
      })
  }

  filterByCurrentMonth (record) {
    if (!record) return false
    const appraisalCreated = utcToLocal(record.createdAt)
    return appraisalCreated.isAfter(this.currentMonth._gte) && appraisalCreated.isBefore(this.currentMonth._lte)
  }

  get currentMonth (): filterDates {
    return this.$store.getters['dashboard/currentMonth']
  }
  }
