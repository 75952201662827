var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading || _vm.processingData)?_c('div',{staticClass:"d-flex flex-column justify-space-around align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}}),(_vm.loading)?_c('p',{staticClass:"text-center"},[_vm._v("Solicitando"),_c('br'),_vm._v("información...")]):(_vm.processingData)?_c('p',{staticClass:"text-center"},[_vm._v("Procesando"),_c('br'),_vm._v("datos...")]):_vm._e()],1):_c('div',{staticClass:"mx-n4"},[_c('h3',{style:({
      'font-size': _vm.smallChart ? '11px' : '14px',
      'line-height': _vm.smallChart ? '10px' : '15px',
      'font-weight': _vm.smallChart ? '500' : 'normal',
      top: _vm.smallChart ? '69px' : '90px',
    })},[_vm._v(" "+_vm._s(_vm.headerTitle.split('_')[0])+" "),_c('br'),_vm._v(" "+_vm._s(_vm.headerTitle.split('_')[1])+" ")]),_c('v-col',[_c('v-row',{staticClass:"d-flex justify-space-around"},[_c('apexchart',{attrs:{"type":"radialBar","options":_vm.chartOptions,"series":_vm.series,"width":_vm.smallChart ? 155 : 190,"height":_vm.smallChart ? 155 : 190}})],1),(_vm.brief !== '')?_c('v-row',{staticClass:"d-flex justify-space-around"},[_c('span',{style:({
          width: _vm.smallChart ? '80px' :'150px',
          padding: _vm.smallChart ? '3px' : '5px',
        })},[_vm._v(" "+_vm._s(_vm.brief)+" ")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }