
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'

@Component
  export default class PeriodRecords extends BaseChart {
  brief = ''

  // Methods
  mounted () {
    this.getData()
  }

  @Prop() processSettings!: Record<string, any>
  @Prop() targetFieldName!: string
  @Prop() defaultTarget!: number
  @Prop() title!: string
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop({ default: undefined, type: Function }) filteringMethod
  @Prop({ default: false, type: Boolean }) smallChart
  @Prop({ default: 1, type: Number }) multiplier

  getData () {
    if (!this.parsedRecords) return
    this.processingData = true
    this.Debug('PROCESS RECORDS', this.records)
    let records = this.parsedRecords
    const settings = this.processSettings?.config
    this.Debug('RECORDS', records)
    this.Debug('SETTINGS', settings)

    if (this.dataTransformer) {
      records = this.dataTransformer(records)
      this.Debug('AFTER TRANSFORM DATA', records)
    }

    if (this.filteringMethod) {
      records = records.filter(this.filteringMethod)
      this.Debug('AFTER FILTERING DATA', records)
    }

    const recordsAmount = records.length
    this.Debug('RECORDS AMOUNT', recordsAmount)
    const recordsTarget = Math.round(((settings?.projectedUtility || settings?.resource)?.[this.targetFieldName] || this.defaultTarget) * this.multiplier)

    const percentage = (recordsAmount / (recordsTarget !== 0 ? recordsTarget : 1)) * 100

    this.brief = `${recordsAmount} de ${recordsTarget}`
    this.series = [Math.round(percentage) || 0]

    this.chartOptions = {
      stroke: {
        lineCap: 'round',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: this.smallChart ? '55%' : '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: '14px',
              fontWeight: 900,
              color: '#737373',
              offsetY: this.title.length > 9 ? 28 : 32,
            },
            value: {
              show: true,
              fontSize: this.smallChart ? '24px' : '35px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -1,
            },
          },
        },
      },
      labels: [this.title],
      fill: {
        type: 'solid',
        colors: [this.getBarColor(percentage)],
      },
    }
    this.processingData = false
  }

  // Getters
  get headerTitle () {
    return this.title.split(' ').join('_')
  }

  get watchData () {
    const { records, processSettings, multiplier } = this
    return {
      records,
      processSettings,
      multiplier,
    }
  }

  // Watchers
  @Watch('watchData', { immediate: true, deep: true })
  onDataChange () {
    this.getData()
  }
  }
