
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { Debounce } from '@/utils/decorators'
  import { fuelColors } from '@/utils/fuelColors'

@Component({})
  export default class CarsByFuel extends BaseChart {
  fuel = []
  stockSpecsAttribute = []
  @Prop() stockSpecs
  @Prop() cars
  fuelColors = fuelColors

  getCountByFuel (fuel) {
    return this.stockSpecsAttribute?.filter(car => car?.componentValue.value === fuel)?.length
  }

  get loading () {
    return !this.stockSpecs?.salesStockView
  }

  async getData () {
    if (!this.stockSpecs) return
    const stock = this.stockSpecs.salesStockView
    if (!stock) return

    const carSpecs = this.cars
    this.stockSpecsAttribute = carSpecs

    let fuel = []
    carSpecs?.forEach(car => {
      const carFuel = car?.componentValue.value
      if (!fuel.includes(carFuel)) {
        fuel.push(carFuel)
      }
    })
    fuel = fuel.sort((prev, next) => {
      return this.getCountByFuel(next) - this.getCountByFuel(prev)
    })
    this.fuel = fuel
    const colors = fuel.map(fuel => fuelColors[fuel])

    this.series = fuel.map(fuel => {
      return carSpecs?.filter(car => car.componentValue.value === fuel)?.length
    })
    this.chartOptions = {
      colors,
      stroke: {
        lineCap: 'round',
      },
      legend: {
        show: false,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '40px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels: fuel,
      fill: {
        type: 'solid',
      },
    }
  }

  // Getters

  // Watchers
  @Watch('stockSpecs', { immediate: true, deep: true })
  @Watch('cars', { immediate: true, deep: true })
  @Debounce(500)
  async onDatesChange () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
