var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('dashboard-card',{attrs:{"title":"Utilidad"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('period-records-utility',{attrs:{"additive-records":[{
        records: _vm.chartData.currentMonth.soldSales,
        path: ['amount'],
        filteringMethod: record => record.saleOrder.deal.stock.stockDetail.acquisitionType.name !== 'consignment',
      }],"sustractive-records":[{
        records: _vm.chartData.currentMonth.soldPurchases,
        path: ['totalCost'],
        filteringMethod: record => record.acquisitionType.name !== 'consignment',
      }],"process-settings":[_vm.chartData?.settings?.sale],"target-field-names":['amount'],"default-targets":[100000000],"title":"Stock propio"}}),_c('period-records-utility',{attrs:{"additive-records":[{
        records: _vm.chartData.currentMonth.soldSales,
        path: ['amount'],
        filteringMethod: record => record.saleOrder.deal.stock.stockDetail.acquisitionType.name === 'consignment',
      }],"sustractive-records":[{
        records: _vm.chartData.currentMonth.soldPurchases,
        path: ['totalCost'],
        filteringMethod: record => record.acquisitionType.name === 'consignment',
      }],"process-settings":[_vm.chartData?.settings?.sale],"target-field-names":['amount'],"default-targets":[100000000],"title":"Consignación"}}),_c('period-records-utility',{attrs:{"additive-records":[{
        records: _vm.chartData.currentMonth.soldSales,
        path: ['amount']
      }],"sustractive-records":[{
        records: _vm.chartData.currentMonth.soldPurchases,
        path: ['totalCost']
      }],"process-settings":[_vm.chartData?.settings?.sale],"target-field-names":['amount'],"default-targets":[100000000],"title":"Directa"}}),_c('period-records-utility',{attrs:{"additive-records":[{
        records: _vm.chartData.currentMonth.utilityCredits,
        path: ['fee']
      }],"sustractive-records":[],"process-settings":[_vm.chartData?.settings?.loanEvaluations],"target-field-names":['amount'],"default-targets":[30000000],"title":"Créditos"}}),_c('period-records-utility',{attrs:{"additive-records":[{
        records: _vm.chartData.currentMonth.soldSales,
        path: ['amount']
      }, {
        records: _vm.chartData.currentMonth.utilityCredits,
        path: ['fee']
      }],"sustractive-records":[{
        records: _vm.chartData.currentMonth.soldPurchases,
        path: ['totalCost']
      }],"process-settings":[
        _vm.chartData?.settings?.sale,
        _vm.chartData?.settings?.loanEvaluations
      ],"target-field-names":['amount', 'amount'],"default-targets":[100000000, 30000000],"title":"Total"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }