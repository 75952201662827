
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { BaseChart } from '@/components/dashboards/baseChart'
  import { mapActions } from 'vuex'
  import { Query } from '@/utils/computed/Query'
  import { formFilter } from '@/graphql/generated-types'
  import { Debounce } from '@/utils/decorators'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
})
  export default class CarsByPrice extends BaseChart {
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  stockAttribute = []
  colors = []
  labels = []
  @Prop() stockSpecs

  filterByPriceRange (data, start, end) {
    return data.filter(item =>
      item.stock?.prices?.[0]?.amount >= start &&
      item.stock?.prices?.[0]?.amount < end
    )
  }

  get loading () {
    return !this.stockSpecs?.salesStockView
  }

  async getData () {
    if (!this.stockSpecs) return
    const stock = this.stockSpecs.salesStockView
    if (!stock) return

    this.stockAttribute = stock
      .filter(item => item.status.status.name !== 'enabling')
      .filter(item => item.status.status.name !== 'retired')

    const colors = ['#13CE66', '#7D67BC', '#6185DB', '#FAD901', '#FF9E2C', '#30426E', '#F03738', '#000']
    this.colors = colors
    const labels = [...colors.map((_color, index) => {
      if (index === 0) return 'Menos de 10M'
      if (index === colors.length - 1) return 'Más de 40M'
      const baseline = 10
      const start = baseline + 5 * (index - 1)
      const end = baseline + 5 * index
      return `De ${start}M a menores de ${end}M`
    }), 'Sin precio']
    this.labels = labels

    this.series = [...colors.map((_color, index) => {
      const baseline = 10000000
      const start = index === 0 ? 0 : baseline + 5000000 * (index - 1)
      const end = index === colors.length - 1 ? Infinity : baseline + 5000000 * index
      return this.filterByPriceRange(this.stockAttribute, start, end).length
    }), this.stockAttribute.filter(item => item.stock?.prices.length === 0).length]

    this.chartOptions = {
      colors,
      stroke: {
        lineCap: 'round',
      },
      legend: {
        show: false,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: '20px',
              fontWeight: 500,
              color: '#737373',
              offsetY: 32,
            },
            value: {
              show: true,
              fontSize: '40px',
              fontWeight: 800,
              color: '#737373',
              offsetY: -17,
            },
          },
        },
      },
      labels,
      fill: {
        type: 'solid',
      },
    }
  }

  // Getters

  // Watchers
  @Watch('stockSpecs', { immediate: true, deep: true })
  @Debounce(500)
  async onDatesChange () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
