import { Component, Prop } from 'vue-property-decorator'
import { BaseChart } from './baseChart'
import dayjs from 'dayjs'

@Component
export class PeriodChart extends BaseChart {
  // Clase base para los graficos de linea
  @Prop() dates // Fecha de inicio y final { start, end }
  @Prop({ default: false, type: Boolean }) monthly // Hace que el grafico sea mensual
  @Prop({ default: false, type: Boolean }) nullZeroValues // Convierte 0 en null
  @Prop({ default: '', type: String }) labelSufix // Sufijo para los labels de cada categoria
  @Prop({ default: false, type: Boolean }) verticalLegend // Define si usa la leyenda vertical en lugar de horizontal
  @Prop({ default: false, type: Boolean }) accumulated // Define si el grafico es de acumulados
  @Prop({ default: 'total', type: String }) totalSufix // Sufijo para la barra del total
  @Prop({ default: false, type: Boolean }) hideMonth
  @Prop({ default: true, type: Boolean }) showTotals // Define si muestra los totales de las categorias
  @Prop({ default: true, type: Boolean }) showTotal // Define si muestra el total de todos los datos
  @Prop({ default: true, type: Boolean }) colorLabels // Define si se colorean los labels de las categorias
  get excludeLastMonth () {
    return this.monthly && dayjs(this.dates.start).isSame(dayjs(), 'month')
  }

  get limitDates () {
    const currentDate = dayjs()
    if (dayjs(this.dates.start).isSame(currentDate, 'month')) {
      return {
        start: this.dates.start,
        end: currentDate.format('YYYY-MM-DDTHH:MM:ss'),
      }
    } else {
      return this.dates
    }
  }

  get limitRange () {
    const { start, end } = this.limitDates
    this.Debug('LIMIT DATES', this.limitDates)
    const prevMonthStart = dayjs(start).subtract(1, 'month')
    const prevMonthEnd = prevMonthStart.endOf('month')

    const fullCurrentMonth = this.generateDayRange(start, dayjs(start).endOf('month'), this.monthly)

    const currentMonth = this.generateDayRange(start, end, this.monthly)
    const prevMonth = this.generateDayRange(prevMonthStart, prevMonthEnd, this.monthly)
    return {
      currentMonth: currentMonth.slice(0, currentMonth.length - (this.monthly ? 0 : 1)),
      prevMonth: prevMonth.length > fullCurrentMonth.length ? prevMonth.slice(0, fullCurrentMonth.length - 1) : prevMonth,
    }
  }

  get currentMonth () {
    const { start } = this.limitDates

    return {
      start: dayjs(start).startOf('month'),
      end: dayjs(start).endOf('month'),
    }
  }

  get currentMonthLength () {
    const { start, end } = this.currentMonth

    return end.diff(start, 'day') + 1
  }

  get limitLength () {
    return this.limitDates.currentMonth.length
  }
}
