
  import Component from 'vue-class-component'
  import { Prop, Watch } from 'vue-property-decorator'
  import dayjs from 'dayjs'
  import { fixAmountFormatted, fixPrice, utcToLocal } from '@/utils/general'
  import { PeriodChart } from '../periodChart'
  import { Legend } from '../Components/Legend'
  import { lowOpacityColor } from '@/utils/dashboards'
  import _ from 'lodash'

interface Data {
  records: Record<string, any>,
  name: string,
  pathToDate: string[],
  color?: string,
  type?: string,
  dataTransformer?: Function,
  filteringMethod?: Function,
}

@Component
  // Grafico de linea para varios tipos de dato
  export default class ABChart extends PeriodChart {
  @Prop() data: Data[]
  @Prop() prevMonthData: Data[]
  @Prop({ default: undefined, type: [String] }) pathToValue // Ruta al valor que suma
  @Prop({ default: undefined, type: Function }) dataTransformer // Funcion que transforma los datos si hace falta
  @Prop({ default: true, type: Boolean }) sortLegends

  legends: Legend[] = []
  async getData () {
    this.Debug('DATA', this.data)
    if ([...this.data, ...this.prevMonthData].some(record => !record.records)) return
    const { limitRange, getObjectAttribute, isCurrency, monthly, months, hideMonth } = this

    const parsedRecords = {
      currentMonth: _.cloneDeep(this.data),
      prevMonth: _.cloneDeep(this.prevMonthData),
    }

    if (this.dataTransformer) {
      parsedRecords.currentMonth = await this.dataTransformer(parsedRecords.currentMonth)
      if (parsedRecords.prevMonth.length > 0) {
        parsedRecords.prevMonth = await this.dataTransformer(parsedRecords.prevMonth)
      }
    }

    for (let i = 0; i < parsedRecords.currentMonth.length; i++) {
      this.$set(parsedRecords.currentMonth[i], 'records', parsedRecords.currentMonth[i].records?.records?.nodes || parsedRecords.currentMonth[i].records?.records || parsedRecords.currentMonth[i].records?.records || parsedRecords.currentMonth[i].records || [])
    }
    for (let i = 0; i < parsedRecords.prevMonth.length; i++) {
      this.$set(parsedRecords.prevMonth[i], 'records', parsedRecords.prevMonth[i].records?.records?.nodes || parsedRecords.prevMonth[i].records?.records || parsedRecords.prevMonth[i].records?.records || parsedRecords.prevMonth[i].records || [])
    }

    const data = parsedRecords.currentMonth.map(record => {
      const { pathToDate, color, name, type } = record
      if (record.dataTransformer) {
        record.records = record.dataTransformer(record.records)
      }
      if (record.filteringMethod) {
        record.records = record.records.filter(record.filteringMethod)
      }

      const serie = limitRange.currentMonth.map(date => {
        const filteredByDate = record.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, pathToDate))).isSame(dayjs(date), monthly ? 'month' : 'day'))
        if (record.pathToValue) {
          let sum = 0
          filteredByDate.forEach(item => {
            sum += getObjectAttribute(item, record.pathToValue)
          })
          return sum
        }
        return filteredByDate.length
      })

      return {
        serie,
        color,
        name,
        type,
      }
    })

    const prevMonthData = parsedRecords.prevMonth.map(record => {
      const { pathToDate, color, name, type } = record
      if (record.dataTransformer) {
        record.records = record.dataTransformer(record.records)
      }

      const serie = limitRange.prevMonth.map(date => {
        return record.records.filter(record => dayjs(utcToLocal(getObjectAttribute(record, pathToDate))).isSame(dayjs(date), monthly ? 'month' : 'day')).length
      })

      return {
        serie,
        color,
        name,
        type,
      }
    })

    this.legends = data
      .map(item => {
        const { name, color, serie } = item
        let value = 0
        serie.forEach(item => value += item !== null ? item : 0)
        value = Math.round(value)
        return {
          name,
          color,
          value,
        }
      })

    this.series = data.map(item => {
      return {
        data: item.serie.map(item => item || 0),
        name: item.name,
        type: item.type,
        color: item.color,
      }
    })

    prevMonthData.forEach(item => {
      this.series.push({
        data: item.serie.map(item => item || 0),
        name: item.name + ' (mes anterior)',
        type: item.type,
        color: lowOpacityColor(item.color),
      })
    })

    this.Debug('SERIES', this.series)

    if (this.accumulated) {
      for (let i = 0; i < this.series.length; i++) {
        this.series[i].data = this.generateAccumulated(this.series[i].data)
      }
    }

    if (!monthly) {
      this.series.forEach(serie => {
        serie.data = this.fillWithNull(serie.data, this.currentMonthLength)
      })
    }

    this.chartOptions = {
      chart: {
        type: 'line',
        stacked: false,
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        show: false,
      },
      stroke: {
        width: 2,
        curve: 'straight',
      },
      markers: {
        size: 3,
        strokeWidth: 0,
      },
      xaxis: {
        categories: monthly ? this.limitRange.currentMonth.map(date => dayjs(date)) : this.generateDayRange(this.currentMonth.start, this.currentMonth.end, monthly),
        type: 'category',
        labels: {
          formatter (val) {
            return monthly ? months[dayjs(val).format('MM')].short : dayjs(val).format(hideMonth ? 'DD' : 'DD/MM')
          },
          style: {
            fontSize: '9px',
            colors: '#737373',
            fontFamily: 'Arial',
          },
        },
      },
      yaxis: {
        labels: {
          formatter (val) {
            if (val >= 1000000) {
              return `${fixAmountFormatted(val / 1000000)}M`
            } else if (isCurrency) {
              return fixPrice(val)
            } else {
              return fixAmountFormatted(val)
            }
          },
          style: {
            fontSize: '9px',
            colors: '#737373',
            fontFamily: 'Arial',
          },
        },
      },
    }
  }

  get watchData () {
    const { data, prevMonthData, dates } = this
    return {
      data,
      prevMonthData,
      dates,
    }
  }

  get loading () {
    const { data, prevMonthData } = this
    return [...data, ...prevMonthData].some(item => !item.records)
  }

  @Watch('watchData', { immediate: false, deep: true })
  async update () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
